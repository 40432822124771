<template>
  <v-dialog
    v-model="internalShowing"
    max-width="400px"
  >
    <v-card>
      <v-card-title>Valores y mediciones</v-card-title>
      <v-card-text>
        <v-form
          v-model="formValid"
        >
          <b10-dynamic-form
            v-model="formModel"
            :schema="formSchema"
            :options="formOptions"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <template
          v-if="readonly"
        >
          <v-btn
            color="primary"
            @click.stop="clickCancelar"
          >
            Cerrar
          </v-btn>
        </template>
        <template
          v-else
        >
          <v-btn
            text
            @click.stop="clickCancelar"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="primary"
            @click.stop="clickAceptar"
          >
            Aceptar
          </v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import internalShowingMixin from '@/mixins/internalShowingMixin'
import { PREGUNTA_CHECKLIST } from '@/utils/consts'

export default {
  mixins: [internalShowingMixin],
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
    readonly: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      formValid: false,
      formModel: {},
      formSchema: {
        type: 'object',
        properties: {}
      },
      formOptions: {
        locale: 'es',
      },
    }
  },
  watch: {
    item: {
      handler: async function (newValue) {
        this.$set(this.formSchema, 'properties', {})
        if (newValue.agrupacion_pregunta_ot.idagrupacion_pregunta_ot) {
          for (const valorColumna of newValue.valoresColumna) {
            const valorTitle = valorColumna.descripcion
            const valorField = valorColumna.idvalor_columna_ot
            if (valorColumna.clasificacion === PREGUNTA_CHECKLIST.clasificacionValor.booleano) {
              this.$set(this.formSchema.properties, valorField, this.newBooleano(valorTitle))
            } else if (valorColumna.clasificacion === PREGUNTA_CHECKLIST.clasificacionValor.textoMultilinea) {
              this.$set(this.formSchema.properties, valorField, this.newTextoMultilinea(valorTitle))
            } else if (valorColumna.clasificacion === PREGUNTA_CHECKLIST.clasificacionValor.entero) {
              this.$set(this.formSchema.properties, valorField, this.newEntero(valorTitle))
            } else if (valorColumna.clasificacion === PREGUNTA_CHECKLIST.clasificacionValor.decimal) {
              this.$set(this.formSchema.properties, valorField, this.newDecimal(valorTitle))
            } else if (valorColumna.clasificacion === PREGUNTA_CHECKLIST.clasificacionValor.fecha) {
              this.$set(this.formSchema.properties, valorField, this.newFecha(valorTitle))
            } else {
              this.$set(this.formSchema.properties, valorField, this.newTexto(valorTitle))
            }
            this.$set(this.formModel, valorField, valorColumna.valor)
          }
        } else {
          if (newValue.pregunta_checklist_ot.clasificacion === PREGUNTA_CHECKLIST.clasificacionValor.booleano) {
            this.$set(this.formSchema.properties, 'valor', this.newBooleano())
          } else if (newValue.pregunta_checklist_ot.clasificacion === PREGUNTA_CHECKLIST.clasificacionValor.textoMultilinea) {
            this.$set(this.formSchema.properties, 'valor', this.newTextoMultilinea())
          } else if (newValue.pregunta_checklist_ot.clasificacion === PREGUNTA_CHECKLIST.clasificacionValor.entero) {
            this.$set(this.formSchema.properties, 'valor', this.newEntero())
          } else if (newValue.pregunta_checklist_ot.clasificacion === PREGUNTA_CHECKLIST.clasificacionValor.decimal) {
            this.$set(this.formSchema.properties, 'valor', this.newDecimal())
          } else if (newValue.pregunta_checklist_ot.clasificacion === PREGUNTA_CHECKLIST.clasificacionValor.fecha) {
            this.$set(this.formSchema.properties, 'valor', this.newFecha())
          } else {
            this.$set(this.formSchema.properties, 'valor', this.newTexto())
          }
          this.$set(this.formModel, 'valor', newValue.pregunta_checklist_ot.valor)
        }
      },
      deep: true
    }
  },
  methods: {
    clickAceptar () {
      this.$emit('set-valor-pregunta', { item: this.item, index: this.index, formData: this.formModel })
      this.internalShowing = false
    },
    clickCancelar () {
      this.internalShowing = false
    },
    newBooleano (title = 'Si/no') {
      return {
        type: 'boolean',
        title,
        readOnly: this.readonly,
        'x-display': 'switch',
        'x-props': {
          'false-value': '0',
          'true-value': '1',
        }
      }
    },
    newTextoMultilinea (title = 'Valor') {
      return {
        type: 'string',
        title,
        readOnly: this.readonly,
        'x-display': 'textarea',
      }
    },
    newEntero (title = 'Valor') {
      return {
        type: 'integer',
        title,
        readOnly: this.readonly,
        'x-props': {
          'clearable': true,
        },
      }
    },
    newDecimal (title = 'Valor') {
      return {
        type: 'number',
        title,
        readOnly: this.readonly,
        'x-props': {
          'clearable': true,
        },
      }
    },
    newFecha (title = 'Valor') {
      return {
        type: 'string',
        format: 'date',
        title,
        readOnly: this.readonly,
        'x-props': {
          'clearable': true,
        },
      }
    },
    newTexto (title = 'Valor') {
      return {
        type: 'string',
        title,
        readOnly: this.readonly,
        'x-props': {
          'clearable': true,
        },
      }
    },
  }
}
</script>